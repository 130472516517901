import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Closure from "../components/Closure";
import { HTMLContent } from "../components/Content";

export const AboutPageTemplate = () => {
  return (
    <div>
      <h1 className="is-size-2 is-hidden-tablet full-width-logo page-title-font">
        About Us
      </h1>
      <section className="is-hidden-touch">
        <div
          className="full-width-image-container margin-top-0 is-hidden-mobile'"
          style={{
            backgroundImage: `url('/img/page-backgrounds/aboutus.jpg')`,
          }}
        >
          <h1
            className="is-hidden-mobile page-title-font highlight"
            style={{
              padding: "4rem",
              color: "black",
            }}
          >
            About Us
          </h1>
        </div>
      </section>
      <section>
        {/* <Closure>
          Please be aware that Lonely Lentil will be closed from Tuesday 28th
          May to Monday 17th June 2024. We’re off on a holiday!
        </Closure> */}
      </section>
      <section>
        <div className="container">
          <div className="columns">
            <div className="column ">
              <div className="section">
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column">
                          <p className="title has-text-centered tile-title">
                            Our beginnings
                          </p>
                          <p>
                            Lonely Lentil began in 2019 as a pop-up shop/food
                            stall, selling a small selection of dry foods, herbs
                            and spices to customers through refilling their
                            containers. We established ourselves in the local
                            community by popping up at market events and by
                            utilising spaces in halls and within premises of
                            other very kind and supportive businesses!
                          </p>
                          <br />
                          <p>
                            We were humbled by the overwhelmingly positive
                            reception and encouragement we received. The local
                            community in Newbury and Thatcham are largely very
                            enthusiastic about the issues of climate change, the
                            environment and reducing plastic consumption. Social
                            media (largely Facebook) greatly helped us to
                            promote Lonely Lentil and gain a lovely local
                            customer base.
                          </p>
                          <br />
                          <p>
                            During our first year of trading, Lonely Lentil's
                            product range rapidly increased! We now offer a
                            range of over 200 products, none of which are
                            supplied to customers in plastic!
                          </p>
                          <br />
                          <p>
                            The majority of our products are dry foods, herbs
                            and spices... but we are also super proud to sell a
                            selection of eco-conscious products produced by
                            other independent businesses based in and around
                            Newbury and Thatcham. Our little table-top shop
                            became something much bigger... a fully-stocked
                            small shop! The venues who hosted us were wonderful:{" "}
                            <a
                              href="https://www.mrsb.kitchen"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Mrs B's Kitchen Cafe
                            </a>
                            ,
                            <a
                              href="https://www.cornexchangenew.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The Corn Exchange Newbury
                            </a>
                            ,
                            <a
                              href="https://www.theglobenewbury.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The Globe Newbury
                            </a>{" "}
                            and
                            <a
                              href="https://www.westberkshireheritage.org/west-berkshire-museum"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              West Berkshire Museum
                            </a>
                            .
                          </p>
                        </div>
                        <div className="column has-text-centered">
                          <img
                            src={
                              "/img/77398670_1011496752530705_9047154025530654720_o.jpg"
                            }
                            alt="us"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column has-text-centered">
                          <img
                            style={{ maxHeight: "500px" }}
                            src={"/img/ethos.png"}
                            alt="us"
                          />
                        </div>
                        <div className="column">
                          <p className="title has-text-centered tile-title">
                            Our ethos
                          </p>
                          <p>
                            Lonely Lentil is passionate about the morals and
                            ethics of what it does. We work with the hope of
                            making an impact in the local area, promoting an
                            ethos of shopping less wastefully and more
                            sustainably . It's about building awareness of more
                            sustainable alternatives to plastic-wrapped
                            groceries and becoming part of the solution.
                          </p>
                          <br />
                          <p>
                            The business also hopes to promote healthy,
                            nourishing eating through our extensive range of
                            natural whole foods. We hope to inspire/encourage
                            customers to cook, experiment and most of all, enjoy
                            our products! We take pride in supplying great
                            quality produce and also in offering such a
                            varied,exciting and extensive selection. Food is a
                            passion at Lonely Lentil, and that’s something we
                            love to share!
                          </p>
                          <br />
                          <p>
                            Lonely Lentil is proud to stand as an independent
                            business alongside many others in the
                            Newbury/Thatcham area. We have lots of lovely local
                            friends who together offer a vibrant range of
                            shopping, dining and leisure experiences to the
                            community. We love to support, promote one another
                            and collaborate wherever possible. Your support is
                            always sincerely appreciated by us ‘Indies’- thank
                            you!
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column">
                          <p className="title has-text-centered tile-title">
                            About Lydia
                          </p>
                          <p>
                            Lydia is the founder of Lonely Lentil. A combination
                            of her personal interests and passions led to the
                            beginnings of the business in 2019. Lydia is married
                            to the wonderful Carlos and has a lot of love for:
                            cooking, animals, travel, recipe books, tea and
                            wine!
                          </p>
                          <blockquote className="blockquote">
                            Cooking is a real passion of mine… I love making
                            aromatic dishes with an abundance of spices and
                            herbs most of all.
                          </blockquote>

                          <p>
                            Lydia especially loves experimenting with recipes
                            from around the world…. Thai, Indian, Middle
                            Eastern, Mauritian, Caribbean and Spanish all being
                            favourites (to name just a few!). Plant-based
                            dishes/menus are a particular interest. When Lydia
                            cooks, it’s serious… only a feast will do when
                            guests are over for dinner! You’ll notice when
                            browsing the Lonely Lentil product selection that
                            global flavours are a strong theme!
                          </p>

                          <blockquote className="blockquote">
                            I love going on a journey when I cook in my little
                            kitchen … the aromas of spice and exotic ingredients
                            can take me away anywhere my imagination does!
                          </blockquote>

                          <p>
                            Lydia’s involvement with a local wildlife
                            rehabilitation charity first sparked her interest in
                            reducing plastic consumption as much as possible.
                          </p>

                          <blockquote className="blockquote">
                            Seeing how discarded rubbish and climate change is
                            affecting our precious wildlife really began opening
                            my eyes to the crisis we face with waste. I decided
                            I wanted to do something to try to make a
                            difference… even a small one.
                          </blockquote>

                          <p>
                            Lydia’s love for animals has always kept her busy
                            since childhood! She is also a proud ‘mum’ to a
                            small menagerie of rescue animals. This has been a
                            lifelong passion.
                          </p>
                          <br />
                          <p>
                            Lydia is very proud to work alongside her wonderful
                            Mum Judy (A.K.A Mummy Lentil) in providing the
                            community with Lonely Lentil products. This really
                            is a true family business!
                          </p>
                        </div>
                        <div className="column has-text-centered">
                          <img src={"/img/img_20190706_120714.jpg"} alt="us" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-parent is-vertical is-7">
                    <div className="tile is-child box ">
                      <p className="title has-text-centered tile-title ">
                        COVID-19
                      </p>
                      <p>
                        The Covid 19 crisis struck in March 2020, which forced
                        Lonely Lentil to quickly adapt to a new way of
                        operating.{" "}
                      </p>
                      <br />
                      <p>
                        Pop-ups were no longer an option. We were determined to
                        continue to provide our wonderful loyal customers with
                        all the store cupboard staples they need to stay
                        healthy, nourished and happy. Lonely Lentil quickly
                        re-invented itself as a local delivery service... a move
                        which was gratefully and enthusiastically received.{" "}
                      </p>
                      <br />
                    </div>
                  </div>

                  <div className="tile is-parent is-vertical is-5">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">Facebook</p>
                      <a
                        href="https://www.facebook.com/lonelylentil"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={"/img/facebook_logo.png"}
                          alt="facebooklogo"
                          style={{ width: "15%" }}
                        />
                      </a>
                    </div>
                    <div className="tile has-text-centered is-child box">
                      <span>
                        <p className="title tile-title">
                          Follow us on Instagram!
                        </p>
                        <a
                          href="https://www.instagram.com/lonelylentil"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={"/img/instagram.png"}
                            alt="instagramlogo"
                            style={{ width: "15%" }}
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  console.log(data);

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
